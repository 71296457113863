*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: #202137c7;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

.sc-info-windows {
  display: flex;
  margin-bottom: 15px;
  gap: 4%;
}

.sc-info-window {
  width: 48%;
  display: flex;
  flex-direction: column;
  background: #373d5c;
  border-radius: 22px;
  padding: 18px;
}

.sc-info-title {
  text-align: center;
  margin-bottom: 10px;
}

span.sc-window-number {
  text-align: center;
  color: #28d0b4;
}

.sc-body .input-title {
  text-align: center;
  display: block;
}

img.header-logo {
  max-height: 40px;
  max-width: 40px;
  image-rendering: -webkit-optimize-contrast;
}

.staking-wrapper {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.auth-btn {
  cursor: pointer;
}

.ant-btn-primary {
  background: rgb(71, 70, 189);
  border: none;
  border-radius: 10px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #e68626;
}

.ant-layout {
  background: none;
}

.staking {
  background: #202137;
  border-radius: 22px;
  padding: 70px 100px;
  max-width: 90%;
}

.staking .ant-typography {
  color: #fff;
  text-align: center;
}

.staking-wrapper .ant-card {
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  display: flex;
  flex-direction: column;
}

.staking-wrapper .ant-card-head {
  border: none;
  color: #fff;
  flex-grow: 1;
}

.staking-wrapper .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 11px;
  font-size: 27px;
}

.staking-wrapper .ant-card-head-title {
  padding-bottom: 0;
}

.sc-buttons button {
  width: 48% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 10px !important;
}

.sc-buttons {
  display: flex;
  justify-content: space-between;
}

.sc-body input {
  border-radius: 10px;
  margin-top: 10px;
}

.sc-harvest-btn {
  margin-top: 10px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

span.multiplier {
  color: #e68626;
}

.staking .ant-card-head-title .ant-typography {
  font-size: 13px;
  white-space: normal;
}

.sc-logo-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.sc-logo-wrapper img {
  max-width: 100%;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  height: 100%;
  max-height: 50px;
  width: auto;
}

.ant-notification {
  top: 80px !important;
}

.sc-body span.warning {
  text-align: center;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  color: red;
}

.staking .heading-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.staking .heading-wrapper .title {
  color: #fff;
  font-size: 32px;
  margin-bottom: 5px;
}

.staking .heading-wrapper .subtitle {
  color: #fff;
  font-size: 21px;
  font-weight: 200;
}

.main {
  padding-bottom: 70px;
}

@media (max-width: 767px) {
  .staking {
    padding: 50px 20px;
    max-width: 100%;
    border-radius: 0;
  }

  .staking-wrapper .ant-card-body {
    padding: 0;
  }

  .sc-body {
    max-width: 100%;
  }

  .staking-wrapper .ant-card-head {
    margin-bottom: 10px;
  }

  .staking-wrapper .ant-card {
    background: hsl(237deg 26% 22%) !important;
    padding: 20px;
  }

  .staking-wrapper .ant-card {
    max-width: 100%;
  }

  .sc-info-window {
    padding: 10px;
  }

  .staking-wrapper .ant-card-head-title {
    white-space: normal;
  }

  .staking-wrapper .title-wrapper {
    font-size: 18px;
  }

  .main {
    margin-top: 64px !important;
    padding-top: 0px;
  }

  .staking .heading-wrapper .title {
    font-size: 27px;
    line-height: 1.3;
  }

  .staking .heading-wrapper .subtitle {
    font-size: 18px;
  }
}
